import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyDKTHDk0gjkiTrAAJojxvXbVoo89558Zq0",
    authDomain: "genx-main.firebaseapp.com",
    databaseURL: "https://genx-main.firebaseio.com",
    projectId: "genx-main",
    storageBucket: "genx-main.appspot.com",
    messagingSenderId: "672453212175"
};

firebase.initializeApp(config);
const root = firebase.database().ref();
const storageRef = firebase.storage().ref();

export const getNavigations = async (callback) => {
    root.child('navigations').on('value', (snapshot) => {
        var data = [];
        snapshot.forEach(ss => {
            data.push(ss.val());
        });
        callback(data);
    });

}

export const getPage = async (pageKey, callback) => {
    root.child('pages').orderByChild("pageKey").equalTo(pageKey).on("value", function (snapshot) {
        callback(snapshot.val());
    });
}

export const getHeader = async (callback) => {
    root.child('header').on("value", function (snapshot) {
        callback(snapshot.val());
    });
}

export const getFooter = async (callback) => {
    root.child('footer').on("value", function (snapshot) {
        callback(snapshot.val());
    });
}

export const currentUser = firebase.auth();

export const loginByEmail = async (email, password) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
}

const addPage = async (pageData) => {
    root.child('pages').push(pageData);
}

export const addSection = async (pageKey, section, isPageExists) => {
    if (isPageExists) {
        root.child('pages').orderByChild("pageKey").equalTo(pageKey).once("value",
            function (snapshot) {
                snapshot.forEach(ele => {
                    ele.ref.update({ sections: section });
                });

            });
    } else {
        let pageToAdd = {
            pageKey: pageKey,
            sections: [section]
        }
        addPage(pageToAdd);
    }
}
export const addSlide = async (pageKey, slide, isPageExists) => {
    if (isPageExists) {
        root.child('pages').orderByChild("pageKey").equalTo(pageKey).once("value",
            function (snapshot) {
                snapshot.forEach(ele => {
                    ele.ref.update({ slides: slide });
                });
            });
    } else {
        let pageToAdd = {
            pageKey: pageKey,
            slides: [slide]
        }
        addPage(pageToAdd);
    }
}

export const uploadImageCallBack = (file) => {
    console.log('file uploAD start', file);

    return new Promise(
        (resolve, reject) => {
            const metadata = {
                contentType: file.type,
            };
            console.log('content type', file.type);
            storageRef.child("images/" + file.name).put(file, metadata).then(async function (snapshot) {
                const url = await snapshot.ref.getDownloadURL();
                console.log('Uploaded a blob or file!', url);
                resolve({ data: { link: url } });
            }).catch((error) => {
                reject(error);
            });
        },
    );
}

export const addBlog = async (pageData) => {
    console.log(pageData);
    root.child('blogs').push(pageData);
}

export const updateBlog = async (key, pageData) => {
    let updatedBlog = {};
    updatedBlog['/blogs/' + key] = pageData;
    console.log(updatedBlog);
    root.update(updatedBlog);
}

export const removeBlog = async (key) => {
    root.child('/blogs/' + key).remove();
}

export const getTop3Blogs = async () => {
    return new Promise((resolve, reject) => {
        try {
            root.child('blogs').ref.orderByKey()
                .limitToLast(3).once('value', (snapshot) => {
                    var data = [];
                    snapshot.forEach(ss => {
                        let item =ss.val();
                        item['key']=ss.key;
                        data.push(item);
                    });
                    resolve(data);
                });
        }
        catch (ex) {
            reject(ex);
        }
    });
}

export const getBlog = async (key) => {
    return new Promise((resolve, reject) => {
        try {
            root.child('blogs').orderByKey().equalTo(key).once("value", function (snapshot) {
                resolve(snapshot.val());
            });
        }
        catch (ex) {
            reject(ex);
        }
    });
}

export const getAllBlogs = async () => {
    return new Promise((resolve, reject) => {
        try {
            root.child('blogs').once('value', (snapshot) => {
                var data = [];
                snapshot.forEach(ss => {
                    data.push({
                        title: ss.val().title, key: ss.key,
                        lastUpdateDate: ss.val().lastUpdateDate
                    });
                });

                resolve(data);
            });
        }
        catch (ex) {
            reject(ex);
        }
    });
}
