import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { getNavigations, getHeader, getFooter } from './lib/firebaseHelper';
import LoginScreen from './screens/LoginScreen';

const HomeScreen = React.lazy(() => import('./screens/HomeScreen'));
const DashboardScreen = React.lazy(() => import('./screens/DashboardScreen'));
const Header = React.lazy(() => import('./components/Header'));
const Footer = React.lazy(() => import('./components/Footer'));
const BlogListScreen = React.lazy(() => import('./screens/BlogListScreen'));
const BlogsScreen = React.lazy(() => import('./screens/BlogsScreen'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { navigations: [], header: null, footer: null };
  }
  async componentWillMount() {
    await getNavigations((navigations) => {
      this.setState({ navigations });
    });
    await getHeader((header) => {
      this.setState({ header });
    });
    await getFooter((footer) => {
      this.setState({ footer });
    });
  }

  render() {
    const { navigations, header, footer } = this.state;
    return (
      <Suspense fallback={<div style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        zIndex: 9999,
        alignItems: "center"
      }}>
        <img src="assets/custom/img/Responsive-Website-Design-Devices.png" alt="GenX Developers" />
      </div>}>
        <>
          {navigations && header &&
            <Header menu={navigations} headerData={header} />
          }
          <Switch>
            {navigations && navigations.map((nav, index) => {
              if (nav.type == "internal") {
                return <Route exact path={nav.refUrl} key={'rou' + index}
                  render={(props) => <HomeScreen {...props} pageKey={nav.pageKey} />}
                />
              }
              if (nav.SubMenu) {
                return (nav.SubMenu.map((subnav, index) => {
                  if (subnav.type == "internal") {
                    return <Route exact path={subnav.refUrl} key={'subnav' + index}
                      render={(props) => <HomeScreen {...props} pageKey={subnav.pageKey} />}
                    />
                  }
                }
                ))
              }
            })}
            <Route exact path="/login" component={LoginScreen} />
            <Route exact path="/dashboard"
              render={(props) => <DashboardScreen {...props} menu={navigations} />}
            />
            <Route exact path="/bloglist"
              render={(props) => <BlogListScreen {...props} menu={navigations} />}
            />
            <Route exact path="/blogs/:blogKey"
              render={(props) => <BlogsScreen {...props} />}
            />
            <Route exact path="/blogs"
              render={(props) => <BlogsScreen {...props} />}
            />
          </Switch>
          {footer &&
            <Footer footerData={footer} />
          }
        </>
      </Suspense>
    );
  }
}

export default App;
